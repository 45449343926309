<template>
  <div>
    <div><van-nav-bar title="分类"  left-arrow
     @click-left="Close" :fixed="true" >
    </van-nav-bar></div>  
   <div style="margin-top:50px" v-if="goodsValue.length>0">
  <!--<van-tree-select
  height="155vw"
  :items="items"
  :main-active-index="mainActiveIndex"
  :active-id="activeId"
  @navclick="onNavClick"

  >-->
  <van-tree-select 
   height="200vw"
  :items="goodsValue"
  :main-active-index.sync="mainActiveIndex"
  :active-id="activeId"
  @click-nav="onNavClick"
>
  <template slot="content">
     <van-cell-group class="goods-cell-group" >
      <van-cell><van-col span="16">{{goodsValue[mainActiveIndex].text}}</van-col><van-col span="8"
       @click="$router.push({name:'Goodsclassifydetail',query: { categoryId:goodsValue[mainActiveIndex].id,page:'1',pageSize:'10',type:type}})">查看更多</van-col></van-cell>
      <van-cell>
      <div class="flex-action"> 
      <div class="flex-cur" style="flex-direction:column" v-for="(i,key) in items" :key="key">
     <img :src="i.cover_image" 
     @click="$router.push({name:'Goodsclassifydetail',query: { categoryId:goodsValue[mainActiveIndex].id,page:'1',pageSize:'20',type:type}})"> 
     <p>{{i.shop_name}}  </p>  
 </div>
    </div>
</van-cell>
    </van-cell-group>
  </template>
</van-tree-select>


  </div>
  </div>
</template>
<script>
import {getObj,getChildList} from '@/api/goods/goods'
export default {
  name: "index",
  data() {
    return {
      goodsValue:[],
      activeId:0,
      mainActiveIndex: 0,
        page: {
          currentPage: 1, // 当前页数
          pageSize: 12, // 每页显示多少条
        },
        items:[],
        type:'3',
        phoneType:'isAndroid'
    };
  },
  created(){
  
},
  mounted () {
     this.phoneType=this.getPhoneType()
  //this.$store.commit('setSession', '1838abd6-bba6-4e8c-84e8-3ced5f6e6e3d')
  console.log(this.$store.state.session); 

   if(this.$route.query.platformId!=null)
   {
      this.type=this.$route.query.platformId
   }
    let params ={'type':this.type}
    // jquery.$('.loading').show();
    getObj(params).then((data) => {
    this.goodsValue=data.data.data.data
    this.goodsValue = this.goodsValue.map(v => ({ 
              ...v,
              text:v.name,
            }))
    this.getChildListData(this.goodsValue[this.mainActiveIndex].id)
    //jquery.$('.loading').hidden();
    //alert(JSON.stringify(this.goodsValue))
                }).catch(err=>{
                        console.log(err)   
                    }
                )
  },
 methods:{
onNavClick(index){
this.mainActiveIndex = index; 
this.getChildListData(this.goodsValue[index].id)
},
//数组去重
unique(arr) { // 根据唯一标识no来对数组进行过滤
const res = new Map();  //定义常量 res,值为一个Map对象实例
//返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
return arr.filter((arr) => !res.has(arr.shop_name) && res.set(arr.shop_name, 1)) 
},
getChildListData(id)
{
  let params ={'categoryId': id}
  const object2 = Object.assign({page: this.page.currentPage, pageSize: this.page.pageSize}, params);
  getChildList(object2).then((data) => {
  this.items=data.data.data.data.data
  this.items= this.unique(JSON.stringify(this.items))
    //alert(JSON.stringify(this.items))
                }).catch(err=>{
                        console.log(err)   
                    }
                )
},
// 返回原生
    Close()
    {
      if(this.typeInfo!=null)
      {
         
        this.$router.back(-1)
      }else{
      if(this.phoneType=='isIOS')
      {
          window.webkit.messageHandlers.close.postMessage('up');   
      }
     if (this.phoneType=='isAndroid') {
      
            window.android.close(); 
       } 
       }
     },
       getPhoneType()
    {
       let u = navigator.userAgent
        let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
         if(isIOS)
        {
          return 'isIOS'
        }
        if (isAndroid) {
         return 'isAndroid'
        } 
     },
 }
};
</script>
<style lang="less" scoped>
.flex-action {
  display: flex;
  flex-wrap: wrap;
}
.flex-cur {
   padding-top: 10px;
   display: flex;
   align-items: center;
   width: 33%;
   flex-direction: column;
   }
.flex-cur img {
  width: 50px;
  height: auto;
}
.van-sidebar-item--select, .van-sidebar-item--select:active {
    color:black;
}
</style>