import https from '@/router/https'
//分类
export function getObj(param) {
    return https.fetchGet('/mallapp/classification/queryClassification', param);
}
//分类
export function getChildList(param) {
    return https.fetchGet('/mallapp/classification/classificationDetail', param);
}
//商品详情
export function getGoodDetail(param) {
    return https.fetchGet('/mallapp/classification/goodsDetail', param);
}
//获取猜你喜欢
export function geGuesstObj(param) {
    return https.fetchGet('/mallapp/juhe/guess', param);
}
//收藏
export function userCollect(param)
{
    return https.fetchPost('/mallapp/usercollect/saveCollect', param);
}
//搜索
export function serachGoods(param)
{
    return https.fetchGet('/mallapp/goodsspu/searchGoodsPage', param);
}
//取消收藏
export function cancelUserCollect(param)
{
    return https.fetchPost('/mallapp/usercollect/del', param );
}
// 淘京拼收藏
export function TJPCollect(param)
{
    return https.fetchPost('/mallapp/usercollect/saveTJP', param );
}
//淘京拼取消收藏
export function cancelCollect(param)
{
    return https.fetchDelete('/mallapp/usercollect/'+param );
}
